/**
 * 設定テンプレートID
 */
/**
 * TOP
 */
// TOP画面
export const TOP = {
  // 入力項目生成テンプレートID
  INFORMATION: 1508,
};

/** --------------------------------------------------------- */

/**
 * マスタ管理
 */
// 営業日マスタ画面
export const BIZDAY_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 2103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2104,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 2105,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 2106,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 2107,
};

// 届先マスタ画面
export const DELIVERY_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1202,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1203,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1204,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1205,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1206,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1207,
  // 一覧項目内部情報テンプレートID
  SEARCH_RESULTS_INFORMATION_TEMPLATE_ID: 1208,
  ONLY_EDIT_RESULTS_TEMPLATE_ID: 1209

};

// 組織マスタ画面
export const DEPARTMENT_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1301,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1302,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1303,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1304,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1305,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1306,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1307,
  GET_CONTROL_FLG_TEMPLATE_ID: 1308,
  ONLY_EDIT_RESULTS_TEMPLATE_ID: 1309
};

// お知らせマスタ画面
export const INFORMATION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1501,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1502,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1503,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1504,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1505,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1506,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1507,
};

// 資材マスタ画面
export const ITEM_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1104,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1105,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1106,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1107,
  // 在庫管理用表示項目生成テンプレートID
  // TODO 森永では未使用
  STOCK_OUTPUT_TEMPLATE_ID: 1108,
  // オリジナル画像ダウンロードテンプレートID
  OUTPUT_IMAGE_TEMPLATE_ID: 1109,
};

// ユーザーマスタ画面
export const USER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1401,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1402,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1404,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1405,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1406,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1407,
};

// セット品マスタ画面
// TEMPLATE2はサブのみ取得する
export const SET_MAIN_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1901,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1902,
  // 詳細項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1903,
  // 詳細項目生成テンプレート2ID
  OUTPUT_TEMPLATE2_ID: 1904,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1905,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1906,
  // 新規入力項目生成テンプレート2ID
  NEW_INPUT_TEMPLATE2_ID: 1907,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1908,
  // 編集入力項目生成テンプレート2ID
  EDIT_INPUT_TEMPLATE2_ID: 1909,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1910,
  // 複製入力項目生成テンプレート2ID
  DUPLICATE_INPUT_TEMPLATE2_ID: 1911,
  // 資材部新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_MATERIAL_ID: 1912,
};

/** --------------------------------------------------------- */

/**
 * 在庫管理
 */
// 製作指示画面
export const PRODUCTION_INSTRUCTION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 601,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 602,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 603,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 604,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 605,
};

// 在庫メンテナンスファイル登録画面
export const REGISTER_STOCK_MAINTENANCE_TEMPLATE = {
  // 確認画面ヘッダーテンプレートID
  CONFIRMATION_HEADER_TEMPLATE_ID: 309,
};

// 在庫メンテナンス管理画面
export const STOCK_MAINTENANCE_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 301,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 302,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 303,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 304,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 307,
};

/** --------------------------------------------------------- */

/**
 * 発注管理
 */
// 発注選択画面
export const WORK_ORDER_TEMPLATE = {
  // 販促資材一覧
  ITEM_TEMPLATE_ID: 109,
  // 届先一覧
  DELIVERIES_TEMPLATE_ID: 110,
  // 注文情報（WORK）
  INPUT_TEMPLATE_ID: 111,
};

// 発注届先画面
export const DELIVERIES_SEARCH_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 111,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 112,

  // 管理ユーザ
  // 表示項目生成テンプレートID
  MANAGEMENT_OUTPUT_TEMPLATE_ID: 113,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 114,

  // 承認ユーザ
  // 表示項目生成テンプレートID
  APPROVAL_OUTPUT_TEMPLATE_ID: 118,
  // CSV出力テンプレートID
  APPROVAL_CSV_TEMPLATE_ID: 119,

  // 一般ユーザ
  // 表示項目生成テンプレートID
  GENERAL_OUTPUT_TEMPLATE_ID: 120,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 121,
};

// 発注販促資材画面
export const ITEM_SEARCH_TEMPLATE = {
  // カテゴリ:通常
  // 検索項目生成テンプレートID
  NORMAL_SEARCH_TEMPLATE_ID: 2201,
  // 一覧項目テンプレートID
  NORMAL_SEARCH_RESULTS_TEMPLATE_ID: 2202,
  // CSV出力テンプレートID
  NORMAL_CSV_TEMPLATE_ID: 2203,

  // カテゴリ:新規
  // 検索項目生成テンプレートID
  NEW_SEARCH_TEMPLATE_ID: 103,
  // 一覧項目テンプレートID
  NEW_SEARCH_RESULTS_TEMPLATE_ID: 104,
  // CSV出力テンプレートID
  NEW_CSV_TEMPLATE_ID: 109,

  // カテゴリ:送込
  // 検索項目生成テンプレートID
  SEND_SEARCH_TEMPLATE_ID: 105,
  // 一覧項目テンプレートID
  SEND_SEARCH_RESULTS_TEMPLATE_ID: 106,
  // CSV出力テンプレートID
  SEND_CSV_TEMPLATE_ID: 110,

  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 107,
};

// 発注販促資材リスト画面
export const ITEM_SEARCH_LIST_TEMPLATE = {
  // 一般ユーザ
  // 検索項目生成テンプレートID
  GENERAL_SEARCH_TEMPLATE_ID: 415,
  // 一覧項目テンプレートID
  GENERAL_SEARCH_RESULTS_TEMPLATE_ID: 417,
  // 表示項目生成テンプレートID
  GENERAL_OUTPUT_TEMPLATE_ID: 424,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 419,

  // 管理・承認ユーザ
  // 検索項目生成テンプレートID
  MANAGEMENT_SEARCH_TEMPLATE_ID: 414,
  // 一覧項目テンプレートID
  MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID: 416,
  // 表示項目生成テンプレートID
  MANAGEMENT_OUTPUT_TEMPLATE_ID: 423,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 418,

  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 420,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 421,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 422,
};

// 発注実績画面
export const ORDER_HISTORY_TEMPLATE = {
  /* 一覧画面 */
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 202,
  // 一覧項目内部情報テンプレートID
  SEARCH_RESULTS_INFORMATION_TEMPLATE_ID: 203,

  // 一般ユーザ
  // 表示項目生成テンプレートID
  GENERAL_OUTPUT_TEMPLATE_ID: 211,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 212,

  // 管理・承認ユーザ
  // 検索項目生成テンプレートID
  MANAGEMENT_OUTPUT_TEMPLATE_ID: 204,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 205,

  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 208,
  // 送込入力項目生成テンプレートID
  SEND_INPUT_TEMPLATE_ID: 214,
  // 入力項目生成テンプレートID(カテゴリ:出荷済み)　※使わない
  SHIPPED_INPUT_TEMPLATE_ID: 209,
  // 送込入力項目生成テンプレートID(カテゴリ:出荷済み)　※使わない
  SEND_SHIPPED_INPUT_TEMPLATE_ID: 215,
  // 入力項目:承認⇒進行中テンプレートID
  INPUT_DURING_SHIPPING_WORK_TEMPLATE_ID: 216,
  // 入力項目:進行中⇒完了テンプレートID
  INPUT_SHIPPED_TEMPLATE_ID: 217,
  // 詳細情報:承認⇒荷受けテンプレートID
  OUTPUT_DURING_SHIPPING_WORK_TEMPLATE_ID: 207,
  // 詳細情報:荷受け⇒個包装テンプレートID
  OUTPUT_DURING_SHIPPING_WORK_TEMPLATE_ID2: 207,
  // 詳細情報:出荷済み(ステータス)テンプレートID
  OUTPUT_SHIPPED_TEMPLATE_ID: 207,

  /* 承認画面 */
  // カテゴリ:配送依頼
  // 入力項目生成テンプレートID
  APPROVAL_REQUEST_INPUT_TEMPLATE_ID: 206,
  // 表示項目生成テンプレートID
  APPROVAL_REQUEST_OUTPUT_TEMPLATE_ID: 207,
  // 金額情報(表示項目生成)テンプレートID
  MONEY_OUTPUT_TEMPLATE_ID: 213,
};

// 注文登録画面
export const ORDER_REGISTER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 104,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 105,
};

// 発注画面
export const QUANTITY_TEMPLATE = {
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 115,
  // 一覧項目内部情報テンプレートID
  SEARCH_RESULTS_INFORMATION_TEMPLATE_ID: 116,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 117,
  // 入力項目初期表示テンプレートID
  INPUT_DEFAULT_TEMPLATE_ID: 122,
  // 送込入力項目生成テンプレートID
  SEND_INPUT_TEMPLATE_ID: 123,
};

/** --------------------------------------------------------- */

/**
 * 計画　利用しない
 */
// 計画一覧画面
export const PROJECT_LIST_TEMPLATE = {
  // 一般ユーザ
  // 検索項目生成テンプレートID
  GENERAL_SEARCH_TEMPLATE_ID: 402,
  // 一覧項目テンプレートID
  GENERAL_SEARCH_RESULTS_TEMPLATE_ID: 404,
  // 表示項目生成テンプレートID
  GENERAL_OUTPUT_TEMPLATE_ID: 406,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 408,

  // 管理・承認ユーザ
  // 検索項目生成テンプレートID
  MANAGEMENT_SEARCH_TEMPLATE_ID: 401,
  // 一覧項目テンプレートID
  MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID: 403,
  // 表示項目生成テンプレートID
  MANAGEMENT_OUTPUT_TEMPLATE_ID: 405,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 407,
  // 入力項目生成テンプレートID
  MANAGEMENT_INPUT_TEMPLATE_ID: 409,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 410,

  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 413,

  // CSV/TSV出力(在庫メンテナンス)テンプレートID
  CSV_STOCK_MAINTENANCE: 905,
};

// 計画数確認画面
export const PROJECT_NUMBER_CONFIRMATION_TEMPLATE = {
  // 共通
  // 計画変更
  PROJECT_CHANGE_INPUT_TEMPLATE_ID: 551,
  // 計画変更(送込)
  PROJECT_CHANGE_INPUT_TEMPLATE_ID2: 559,

  // イベント一覧項目テンプレートID
  EVENT_SEARCH_RESULTS_TEMPLATE_ID: 552,
  // イベント表示項目生成テンプレートID
  EVENT_OUTPUT_TEMPLATE_ID: 553,

  // 一般ユーザ・承認ユーザ
  // 検索項目生成テンプレートID
  GENERAL_SEARCH_TEMPLATE_ID: 555,
  // 一覧項目テンプレートID
  GENERAL_SEARCH_RESULTS_TEMPLATE_ID: 557,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 557,

  // 管理
  // 検索項目生成テンプレートID
  MANAGEMENT_SEARCH_TEMPLATE_ID: 554,
  // 一覧項目テンプレートID
  MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID: 556,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 556,

  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 558,
};

// 計画数入力画面
export const PROJECT_NUMBER_INPUT_TEMPLATE = {
  // 一般ユーザ
  // 計画変更
  GENERAL_PROJECT_CHANGE_INPUT_TEMPLATE_ID: 502,

  // 管理
  // 計画変更
  MANAGEMENT_PROJECT_CHANGE_INPUT_TEMPLATE_ID: 501,

  // イベント一覧項目テンプレートID
  EVENT_SEARCH_RESULTS_TEMPLATE_ID: 503,
  // イベント表示項目生成テンプレートID
  EVENT_OUTPUT_TEMPLATE_ID: 504,

  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 505,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 506,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 507,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 508,
  // 入力項目初期表示テンプレートID
  INPUT_DEFAULT_TEMPLATE_ID: 509,
};

/** --------------------------------------------------------- */

/**
 * 事務局
 */
// 情報出力画面
export const SECRETARIAT_OUTPUT_TEMPLATE = {
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 901,
  // ファイル出力用テンプレートID
  SEARCH_RESULTS_FILE_TEMPLATE_ID: 902,
  // CSV/TSV出力(注文情報)テンプレートID
  CSV_ORDER_TEMPLATE_ID: 904,
  // CSV/TSV出力(注文情報:送込)テンプレートID
  CSV_SEND_ORDER_TEMPLATE_ID: 904,
  // CSV/TSV出力(在庫メンテナンス)テンプレートID
  CSV_STOCK_MAINTENANCE: 905,
};

// 発送ステータスファイル登録画面
export const REGISTER_SHIPPING_RESULT_TEMPLATE = {
  // 確認画面ヘッダーテンプレートID
  CONFIRMATION_HEADER_TEMPLATE_ID: 701,
};

// ファイル一括登録状況(JOB)画面
export const FILE_UPLOAD_HISTORY_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2001,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2002,
  // 詳細項目テンプレートID
  SEARCH_RESULTS_DETAIL_TEMPLATE_ID: 2003,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2004,
};
