import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/shared/service/common.service';
import { GenerateService } from '../../../generate.service';
import {
  DISPLAY_INFORMATIONS_API_CONSTANT,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { GENERATE_INPUT_TYPE } from '../../constant';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Validator } from 'src/app/shared/validator/validator';
import { GenerateInputErrorMessage } from '../generate-input-error-message/generate-input-error-message';
import { GenerateInputFormConfirmation } from './generate-input-form-confirmation';
import {
  API_URL_INPUT_SET,
  API_URL_DISPLAY_SET_ITEM,
  API_URL_DUP_SET_ITEM,
  API_URL_DEPARTMENT
} from 'manager/http-constants_key';
import { DELIVERY_TEMPLATE, DEPARTMENT_TEMPLATE, SET_MAIN_TEMPLATE } from 'manager/template-constant';
import { GenerateInputSetMaterialComponent } from '../generate-input-form/generate-input-set-material/generate-input-set-material.component';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { forkJoin, Observable } from 'rxjs';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { MessageData, ToastMessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { STOCK_FILE_DATA } from 'src/app/pages/project/project-list/constant';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { DEPARTMENT$DEPARTMENT_TYPE } from 'src/app/shared/constant/db-constant';

@Component({
  selector: 'app-generate-input-form',
  templateUrl: './generate-input-form.component.html',
  styleUrls: ['./generate-input-form.component.scss'],
})

/**
 * 入力フォーム
 */
export class GenerateInputFormComponent implements OnInit {
  @ViewChild(GenerateInputSetMaterialComponent)
  generateInputSetMaterialComponent: GenerateInputSetMaterialComponent;

  // 画面.入力フォーム
  generateInputForm: FormGroup = this.formBuilder.group(Object());

  // 「資材」用.入力フォーム
  generateInputMaterialForm: FormGroup = this.formBuilder.group(Object());

  // インプット.入力項目生成条件
  @Input() inputOueryParameters: object;

  // アウトプット.入力フォーム情報
  @Output() generateInputInformation = new EventEmitter<FormGroup>();
  
  //アウトプット.
  @Output() generateInputMaterialInfomation = new EventEmitter<any>();


  // アウトプット.入力フォームエラー情報
  @Output() generateInputErrorInformation = new EventEmitter<
    GenerateInputErrorMessage[]
  >();

  // 画面.入力項目生成
  generateInputList: any[] = new Array();

  // 「資材」.入力項目生成
  generateInputMaterialList: any[] = new Array();

  // 入力フォーム確認画面メイン用出力オブジェクトリスト
  generateInputFormConfirmationList: GenerateInputFormConfirmation[] =
    new Array();

  // 入力フォーム確認画面セット品用出力オブジェクトリスト
  generateInputMaterialFormConfirmationList: any[] = new Array();

  // 入力エラーメッセージオブジェクト
  generateInputErrorMessageList: GenerateInputErrorMessage[] = new Array();

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 登録ボタン押下フラグ(データ登録中にボタン押下させないフラグ)
  insertFlag: boolean;

  // 対象ID格納先
  pkeyId: string;

  // 業務入力チェックエンドポイント格納先
  endPointWorkInputCheck: string;

  // 資材画面表示フラグ
  setItemFlag: boolean;
  
  // 入力画面ダイアログ表示フラグ
  inputModal: boolean;

  title: String;

  // 登録資材ヘッダー情報格納先(サブ)
  subColumnOrder: any[] = new Array();

  // 登録資材検索結果一覧格納先(サブ)画面用
  subSearchResultsList: any[] = new Array();

  // 登録資材検索結果一覧格納先(サブ)内部用
  subDetailList: any[] = new Array();

  // 資材選択フラグ
  selectedFlag: boolean;

  lastSelectedValues: { [key: string]: string } = {};

  constructor(
    private generateService: GenerateService,
    private loadingState: LoadingState,
    private messageData: MessageData,
    private commonService: CommonService,
    private validator: Validator,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private formBuilder: FormBuilder,
    private dbOperationService: DbOperationService
  ) {
    // PrimeNGを日本語化
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  ngOnInit(): void {}

  /**
   * 入力項目生成
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param endPointInput 入力項目生成用エンドポイント
   * @param endPoint 編集、複製用検索エンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象ID
   * @param endPointWorkInputCheck 業務入力チェックエンドポイント
   */
  public initial(
    inputType: string,
    endPointInput: string,
    endPoint: string,
    templateId: number,
    pkeyId: string,
    endPointWorkInputCheck?: string
  ) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.GENERATE_INPUT);

    // 対象IDを一時保存
    this.pkeyId = pkeyId;

    // 業務入力チェックエンドポイントを一時保存
    this.endPointWorkInputCheck = endPointWorkInputCheck;

    // 画面.入力フォームを初期化
    {
      this.generateInputList = new Array();
      this.generateInputForm = this.formBuilder.group(Object());
      this.generateInputMaterialForm = this.formBuilder.group(Object());
      this.subSearchResultsList = new Array();
    }

    // 入力フォーム状態初期化
    this.resetFlag();

    // セット品の場合資材登録部表示
    if (templateId == SET_MAIN_TEMPLATE.NEW_INPUT_TEMPLATE_ID
      || templateId == SET_MAIN_TEMPLATE.EDIT_INPUT_TEMPLATE_ID 
      || templateId == SET_MAIN_TEMPLATE.DUPLICATE_INPUT_TEMPLATE_ID) {
      this.setItemFlag = true;
    } else { this.setItemFlag = false; }


    /* 入力項目情報取得処理  */
    this.generateService
      .getGenerateInput(endPointInput, templateId, this.inputOueryParameters)
      .subscribe((response) => {
        // 入力項目情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 入力項目情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }

        // 入力フォーム用コントロール作成
        for (const generateInput of response.body) {
          // 入力フォームグループに入力フォームコントロールを追加
          this.generateInputForm.addControl(
            generateInput.column_id,
            this.formBuilder.control(generateInput.column_defualt, {
              asyncValidators: [
                this.validator.CheckValidator(
                  generateInput.table_id,
                  generateInput.column_id,
                  generateInput.input_type
                ),
              ],
            })
          );

          if (generateInput.column_id == 'suppliers_code'){
            this.generateInputForm.get('suppliers_code').valueChanges.subscribe(value => {
              this.removeNonAlphanumeric('suppliers_code', value);
            });
          }
          if (generateInput.column_id == STOCK_FILE_DATA.DEPARTMENT_CODE && 
            templateId == DELIVERY_TEMPLATE.NEW_INPUT_TEMPLATE_ID
            ){
            this.generateInputForm.get(STOCK_FILE_DATA.DEPARTMENT_CODE).valueChanges.subscribe(value => {
              this.dbOperationService.getSingleData(
                API_URL_DEPARTMENT,
                DEPARTMENT_TEMPLATE.GET_CONTROL_FLG_TEMPLATE_ID,
                STOCK_FILE_DATA.DEPARTMENT_CODE,
                value
              ).subscribe((response) => {
                if (response.body.length > 0) {
                  const value = response.body[0].control_flg;
                  this.generateInputForm.get("control_flg").setValue(value);
              } else {
                  this.generateInputForm.get("control_flg").setValue(null);
              }
              }
              )
            })
          }
          // 入力項目タイプの判定
          if (
            generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.CHECKBOX_TYPE
          ) {
            // 入力項目タイプが"checkbox"の場合

            // 入力フォームコントロールにチェックが外れた場合のバリデーションチェックを実施を追加
            this.generateInputForm.get(generateInput.column_id).updateOn;
          }

          // 登録種別が編集 かつ
          // 編集不可項目か否か
          if (
            GENERATE_INPUT_TYPE.EDIT == inputType &&
            (generateInput.column_input_update == '0' ||
              generateInput.column_input_update == '2')
          ) {
            // 登録種別が編集の場合 かつ
            // 編集不可項目の場合

            // 入力フォームコントロールを非活性化
            this.generateInputForm.get(generateInput.column_id).disable();
          }
          // 登録種別が編集 かつ
          // 編集不可項目か否か
          if (
            GENERATE_INPUT_TYPE.NEW == inputType &&
            generateInput.column_input_update == '0' && generateInput.column_id == "control_flg"
          ) {
            // 登録種別が編集の場合 かつ
            // 編集不可項目の場合

            // 入力フォームコントロールを非活性化
            this.generateInputForm.get(generateInput.column_id).disable();
          }
          // 登録種別が編集 かつ
          // 編集不可項目か否か
          if (
            GENERATE_INPUT_TYPE.DUPLICATE == inputType &&
            generateInput.column_input_update == '0' && generateInput.column_id == "control_flg"
          ) {
            // 登録種別が編集の場合 かつ
            // 編集不可項目の場合

            // 入力フォームコントロールを非活性化
            this.generateInputForm.get(generateInput.column_id).disable();
          }
        }

        // 入力項目情報を画面.入力項目生成に格納
        this.generateInputList = response.body;

        // 登録種別の判定
        if (GENERATE_INPUT_TYPE.NEW == inputType) {
          // 登録種別が新規の場合
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
        } else if (GENERATE_INPUT_TYPE.EDIT == inputType) {
          // 登録種別が編集の場合
          // 更新用の詳細情報取得
          this.editGenerateDisplay(endPoint, templateId, pkeyId);
        } else if (GENERATE_INPUT_TYPE.DUPLICATE == inputType) {
          // 登録種別が複製の場合
          // 複製用の詳細情報取得
          this.duplicateGenerateDisplay(endPoint, templateId, pkeyId);
        }
      });
      
    /* 「資材」用入力項目取得処理  */
    if( this.setItemFlag ){
      /* ヘッダー情報取得処理(画面用) */
      if (GENERATE_INPUT_TYPE.NEW == inputType) {
        this.dbOperationService
        .getHeaderList(SET_MAIN_TEMPLATE.NEW_INPUT_TEMPLATE2_ID)
        .subscribe((response) => {
          this.subColumnOrder = response.body;
        });
      } else if (GENERATE_INPUT_TYPE.EDIT == inputType) {
        this.dbOperationService
        .getHeaderList(SET_MAIN_TEMPLATE.EDIT_INPUT_TEMPLATE2_ID)
        .subscribe((response) => {
          this.subColumnOrder = response.body;
        });
      } else if(GENERATE_INPUT_TYPE.DUPLICATE == inputType) {
        this.dbOperationService
        .getHeaderList(SET_MAIN_TEMPLATE.DUPLICATE_INPUT_TEMPLATE2_ID)
        .subscribe((response) => {
          this.subColumnOrder = response.body;
        });
      }

      /* 入力項目情報取得処理  */
      this.generateService
      .getGenerateInput(API_URL_INPUT_SET, SET_MAIN_TEMPLATE.OUTPUT_TEMPLATE2_ID)
      .subscribe((response) => {
        // 入力項目情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 入力項目情報が取得されなかった場合
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
          return;
        }
        // 入力項目情報を画面.入力項目生成に格納
        this.generateInputMaterialList = response.body;

        // 登録種別の判定
        if (GENERATE_INPUT_TYPE.NEW == inputType) {
          // 登録種別が新規の場合
          // 入力チェックのため登録できない形でフォームを作成する
          this.makeInputForm(this.subColumnOrder);
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
        } else if (GENERATE_INPUT_TYPE.EDIT == inputType ){
          // 既存の詳細情報を取得
          this.getItemGenerateDisplay(API_URL_DISPLAY_SET_ITEM, SET_MAIN_TEMPLATE.EDIT_INPUT_TEMPLATE2_ID, pkeyId, inputType);
        } else {
          // 既存の詳細情報を取得
          this.getItemGenerateDisplay(API_URL_DISPLAY_SET_ITEM, SET_MAIN_TEMPLATE.DUPLICATE_INPUT_TEMPLATE2_ID, pkeyId, inputType);
        }
      });
    }
  }

  /**
   * 更新用の詳細情報取得
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  private editGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 更新用の詳細情報取得
    this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId)
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }

        // 詳細情報初期値セット
        this.setFormControlValue(response.body);
        if (templateId == DELIVERY_TEMPLATE.EDIT_INPUT_TEMPLATE_ID || 
          templateId == DELIVERY_TEMPLATE.DUPLICATE_INPUT_TEMPLATE_ID)
        {
          this.generateInputForm.get(STOCK_FILE_DATA.DEPARTMENT_CODE).valueChanges.subscribe(value => {
            this.dbOperationService.getSingleData(
              API_URL_DEPARTMENT,
              DEPARTMENT_TEMPLATE.GET_CONTROL_FLG_TEMPLATE_ID,
              STOCK_FILE_DATA.DEPARTMENT_CODE,
              value
            ).subscribe((response) => {
              if (response.body.length > 0) {
                const value = response.body[0].control_flg;
                this.generateInputForm.get("control_flg").setValue(value);
            } else {
                this.generateInputForm.get("control_flg").setValue(null);
            }
            }
            )
          })
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
      });
  }

  /**
   * 複製用の詳細情報取得
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  private duplicateGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 複製用の詳細情報取得
    this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId, true)
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          return;
        }

        // 詳細情報初期値セット
        this.setFormControlValue(response.body);
        if (templateId == DELIVERY_TEMPLATE.EDIT_INPUT_TEMPLATE_ID || 
          templateId == DELIVERY_TEMPLATE.DUPLICATE_INPUT_TEMPLATE_ID)
        {
          this.generateInputForm.get(STOCK_FILE_DATA.DEPARTMENT_CODE).valueChanges.subscribe(value => {
            this.dbOperationService.getSingleData(
              API_URL_DEPARTMENT,
              DEPARTMENT_TEMPLATE.GET_CONTROL_FLG_TEMPLATE_ID,
              STOCK_FILE_DATA.DEPARTMENT_CODE,
              value
            ).subscribe((response) => {
              if (response.body.length > 0) {
                const value = response.body[0].control_flg;
                this.generateInputForm.get("control_flg").setValue(value);
            } else {
                this.generateInputForm.get("control_flg").setValue(null);
            }
            }
            )
          })
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
      });
  }

  /**
   * 詳細情報初期値セット
   * @param generateDisplayInformation
   */
  private setFormControlValue(generateDisplayInformation: any[]) {
    // 入力項目分ループ
    for (const generateInput of this.generateInputList) {
      // 入力項目タイプを判定
      if (
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ||
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE ||
        generateInput.input_type ==
          INPUT_INFORMATIONS_API_CONSTANT.PASSWORD_TYPE ||
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.YEAR_MONTH_TYPE ||
        generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.RADIO_INPUT_NUMBER
      ) {
        // 入力項目タイプが以下の場合
        // テキスト or
        // 数値 or
        // 日付 or
        // テキストエリア
        // パスワードの場合

        // 詳細情報のカラム値をフォームコントロールに格納
        this.generateInputForm
          .get(generateInput.column_id)
          .setValue(
            this.commonService.getArrayObjectValue(
              generateDisplayInformation,
              DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
              DISPLAY_INFORMATIONS_API_CONSTANT.DATA,
              generateInput.column_id
            )
          );
      } else {
        // 入力項目タイプが以下の場合
        // ラジオボタン or
        // チェックボックス or
        // シングルセレクト or
        // マルチセレクトの場合

        // 詳細情報のカラム値(コード)を取得する
        const dataMulti = this.commonService.getArrayObjectValue(
          generateDisplayInformation,
          DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          DISPLAY_INFORMATIONS_API_CONSTANT.DATA_MULTI,
          generateInput.column_id
        );

        // 入力項目タイプを判定
        if (
          generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE ||
          generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
        ) {
          // 入力項目タイプが以下の場合
          // ラジオボタン or
          // シングルセレクトの場合

          this.lastSelectedValues[generateInput.column_id] = dataMulti.join(CONSTANT.COMMA);

          // カラム値(コード)を文字列に変換してフォームコントロールに格納
          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(dataMulti.join(CONSTANT.COMMA));
        } else {
          // 入力項目タイプが以下の場合
          // チェックボックス or
          // マルチセレクトの場合

          // カラム値(コード)をフォームコントロールに格納
          this.generateInputForm
            .get(generateInput.column_id)
            .setValue(dataMulti);
        }
      }
    }
  }

  /**
   * セット品サブ用(編集・複製)詳細情報取得、フォーム設定、CodeConvFLAG=0を設定
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   * @param inputType 対象の取得情報
   */
   private getItemGenerateDisplay(
    endPoint: string,
    templateId: number,
    pkeyId: string,
    inputType: string
  ) {
    // 編集用の詳細情報取得
    if( GENERATE_INPUT_TYPE.EDIT == inputType ) {
      this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId, false, '&CodeConvFLAG=0')
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
          return;
        }

        // 詳細情報初期値セット
        this.subDetailList = response.body;
        // フォーム作成
        this.makeInputForm(this.subDetailList);
        // フォームの初期値設定
        this.setFormControlDefaultValue(this.subDetailList);
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
      });
    // 複製用の詳細情報取得
    } else if ( GENERATE_INPUT_TYPE.DUPLICATE == inputType ){
      this.generateService
      .getGenerateDisplay(endPoint, templateId, pkeyId, true, '&CodeConvFLAG=0')
      .subscribe((response) => {
        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);
          return;
        }

        // 詳細情報初期値セット
        this.subDetailList = response.body;
        // フォーム作成
        this.makeInputForm(this.subDetailList);
        // フォームの初期値設定
        this.setFormControlDefaultValue(this.subDetailList);
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, LOADING_KEY.GENERATE_INPUT);
      });

    }
  }

  /**
   * セット品サブ用入力項目初期値セット
   */
   private setFormControlDefaultValue(
    subDetailList: any
   ) {

    /* 入力フォーム初期値設定 */
    // セット品サブ分ループ
    let count : number = 0;
    for (const subDetail of subDetailList) {
      // セット品サブ入力項目分ループ
      for (const inputItem of this.generateInputMaterialList) {
        if(inputItem.column_id == "config_count")
        // 子フォームグループの入力フォームに入力値をセット
        this.generateInputMaterialForm.controls[inputItem.column_id + count ]
          .setValue(
            subDetail[inputItem.column_id]
          );
      }
      count++;
    }
    this.subSearchResultsList = subDetailList;
    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadSleepEnd(0.3,  LOADING_KEY.GENERATE_INPUT);
  }

  /**
   * 入力項目値確認
   */
  public inputVerification() {
    // 入力エラーメッセージオブジェクトを初期化
    this.generateInputErrorMessageList = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: '',
        background_color: '',
        opacity: 0.3,
      }),
      LOADING_KEY.GENERATE_INPUT
    );

    /* 入力項目値バリデーションチェック */
    // 非同期同時実行リスト
    let task: Observable<any>[] = [
      /* 共通バリデーションチェック */
      this.generateService.multiValidationResult(
        this.generateInputList[0].table_id,
        this.generateInputForm.value
      ),
    ];

    // 業務入力チェックエンドポイントが存在するか否か
    if (this.endPointWorkInputCheck) {
      // 業務入力チェックエンドポイントが存在する場合

      task.push(
        /* 業務用バリデーションチェック */
        this.generateService.workValidationResult(
          this.endPointWorkInputCheck,
          this.pkeyId,
          this.generateInputForm.value
        )
      );
    }

    // 非同期同時実行
    forkJoin(task).subscribe((dataList) => {
      // レスポンスを結合
      const data = this.generateService.JoinValidationResponseList(dataList);

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

      // 入力項目分ループ
      for (const item of this.generateInputList) {
        // 入力項目に該当するエラーメッセージが存在するか判定
        if (data.body[0].message[item.column_id]) {
          // エラーメッセージが存在する場合

          //  入力フォームエラー情報を生成
          let generateInputErrorMessage: GenerateInputErrorMessage =
            new GenerateInputErrorMessage();
          //  入力フォームエラー情報を格納
          generateInputErrorMessage.columnId = item.column_id;
          generateInputErrorMessage.columnName = item.column_name;
          generateInputErrorMessage.columnErrorMessage =
            data.body[0].message[item.column_id];
          this.generateInputErrorMessageList.push(generateInputErrorMessage);
        }
      }

      // 親コンポーネントへ入力フォームエラー情報を渡す
      this.generateInputErrorInformation.emit(
        this.generateInputErrorMessageList
      );

      // バリデーションチェック状態を確認
      if ('0' != data.body[0].result) {
        // チェック状態が異常の場合

        // 処理を終了
        return;
      }

      // ページアンカーへの画面スクロールを実施
      this.pageTopScroll();

      /* 入力値確認データを作成 */
      {
        // メイン入力値確認用リスト生成
        this.generateInputFormConfirmationList = new Array();
        // サブ入力値確認用リスト生成
        this.generateInputMaterialFormConfirmationList = new Array();

        // 入力項目分ループ
        for (const generateInput of this.generateInputList) {
          // 画面表示不可項目か否か
          if (generateInput.column_input_update == '4') {
            // 画面表示不可項目の場合

            continue;
          }

          // 入力値確認用オブジェクト
          let generateInputFormConfirmation: GenerateInputFormConfirmation =
            new GenerateInputFormConfirmation();

          // 入力項目タイプを判定
          if (
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.YEAR_MONTH_TYPE
          ) {
            // 入力項目タイプが以下の場合
            // テキスト or
            // 数値 or
            // 日付 or
            // テキストエリアの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;
            // 入力項目値のIDを格納
            generateInputFormConfirmation.columnId = 
              generateInput.column_id
            // FormControlの入力値を格納
            generateInputFormConfirmation.columnData =
              this.generateInputForm.controls[generateInput.column_id].value;

            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else if (
            generateInput.input_type ==
            INPUT_INFORMATIONS_API_CONSTANT.PASSWORD_TYPE
          ) {
            // パスワードの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormControlの入力値を'●'に置換して格納
            generateInputFormConfirmation.columnData = '●'.repeat(
              this.generateInputForm.controls[generateInput.column_id].value
                .length
            );
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else if (
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE ||
            generateInput.input_type ==
              INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE
          ) {
            // 種類が以下の場合
            // ラジオボタン or
            // シングルセレクトの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormControlの入力値を名称に変換して格納
            generateInputFormConfirmation.columnData =
              this.commonService.getArrayObjectValue(
                generateInput.column_code_list_multi,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                this.generateInputForm.controls[generateInput.column_id].value
              );
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else if (generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.RADIO_INPUT_NUMBER) {
            // 種類が以下の場合
            // グループラジオ + 入力番号

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            const columValue: string = this.generateInputForm.controls[generateInput.column_id].value;

            let columnData = ""
            if (columValue.length != 0){
              const radioValue = String(columValue).split(",");
              columnData = `${this.commonService.getArrayObjectValue(
                generateInput.column_code_list_multi,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                radioValue[0]
              ) || ""}, ${radioValue[1] || ""}`;
            }

            // FormControlの入力値を名称に変換して格納
            generateInputFormConfirmation.columnData = columnData;
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          } else if (generateInput.input_type == INPUT_INFORMATIONS_API_CONSTANT.CHECKBOOK_TREE_TYPE) {
            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName = generateInput.column_name;

            // Use flatMap to flatten the children arrays
            const listData = generateInput.column_code_list_multi.flatMap(object => object.children);

            // FormGroupの入力値(複数)を名称に変換して格納
            generateInputFormConfirmation.columnData = this.commonService
              .getArrayObjectMultipleValue(
                listData,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_DATA,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_LABEL,
                this.generateInputForm.controls[generateInput.column_id].value
              )
              .join(CONSTANT.COMMA);
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(generateInputFormConfirmation);
            continue;
          } else {
            // 種類が以下の場合
            // チェックボックス or
            // マルチセレクトの場合

            // 入力項目値の名称を格納
            generateInputFormConfirmation.columnName =
              generateInput.column_name;

            // FormGroupの入力値(複数)を名称に変換して格納
            generateInputFormConfirmation.columnData = this.commonService
              .getArrayObjectMultipleValue(
                generateInput.column_code_list_multi,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
                INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
                this.generateInputForm.controls[generateInput.column_id].value
              )
              .join(CONSTANT.COMMA);
            // 入力値確認用オブジェクトを入力値確認用リストに追加
            this.generateInputFormConfirmationList.push(
              generateInputFormConfirmation
            );
            continue;
          }
        }

        if (this.setItemFlag) {
          let count : number = 0;
          // 各資材の項目値でチェックする。1つもないまたは複数ある場合はエラー
          // const setExterior = this.subSearchResultsList.filter(val => val.item_code.match(/-SET-/g))
          const setExterior = this.subSearchResultsList.filter(val => val.col_var1 !== "")
          if (setExterior.length == 0) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E80003),
                detail: this.commonService.msg(MESSAGE_CODE.E00040),
              })
            );
            return;
          }
          if (setExterior.length > 1) {
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E80003),
                detail: this.commonService.msg(MESSAGE_CODE.E00041),
              })
            );
            return;
          }

          // 確認画面用(セット品)
          // セット品サブ項目のセット
          // 資材件数分ループ
          for (let listData of this.subSearchResultsList) {
            // 入力項目分ループ
            for (const generateInputMaterial of this.generateInputMaterialList) {
              // 構成数の場合はリストに追加
              if(generateInputMaterial.column_id == "config_count" ) {
                listData[generateInputMaterial.column_id] = this.generateInputMaterialForm.controls[generateInputMaterial.column_id + count ].value;
              }
              continue;
            }
            count++;
            listData["set_code"] = this.generateInputForm.controls["set_code"].value;
            // サブ用入力値確認用オブジェクトをサブ用入力値確認用リストに追加
            this.generateInputMaterialFormConfirmationList.push(listData);
          }

          // セット外装資材の構成数チェック
          for (let listData of this.subSearchResultsList) {
            if (listData.col_var1 !== "" && listData.config_count !== 1) {
              // 警告メッセージ
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.WARN,
                  summary: this.commonService.msg(MESSAGE_CODE.E80003),
                  detail: this.commonService.msg(MESSAGE_CODE.E00042),
                })
              );
              return;
            }
          }
        }
        // 確認画面を表示
        this.confirmationFlag = true;
      }
    });
  }

  /**
   * 確認画面戻るボタン
   */
  protected returnConfirmationButton() {
    // 確認画面から入力画面へ遷移する
    this.confirmationFlag = false;

    // ページアンカーへの画面スクロールを実施
    this.pageTopScroll();
  }

  /**
   * ページアンカーへの画面スクロール
   */
  private pageTopScroll() {
    // 入力エラーメッセージのページアンカーが存在するか否か
    if (document.getElementById('generateInputErrorTop')) {
      // 入力エラーメッセージのページアンカーが存在する場合

      // 入力エラーメッセージのページアンカーにスクロール
      let top = document.getElementById('generateInputErrorTop');
      top.scrollIntoView();
      top = null;
    } else {
      // 入力エラーメッセージのページアンカーが存在しない場合

      // 入力フォームのページアンカーにスクロール
      let top = document.getElementById('generateInputFormTop');
      top.scrollIntoView();
      top = null;
    }
  }

  /**
   * 入力フォーム情報返却
   */
  public returnGenerateInputForm() {
    // 登録ボタン押下フラグをONにする(登録、戻るボタン非活性化)
    this.insertFlag = true;
    // セット品フラグを格納
    let flag : string = this.setItemFlag.toString();
    sessionStorage.setItem('setItemFlag',flag);
    if(this.setItemFlag){
      // 親コンポーネントへセット品サブ情報を渡す
      this.generateInputMaterialInfomation.emit(JSON.stringify(this.generateInputMaterialFormConfirmationList));
    }
    // 親コンポーネントへ入力フォーム情報を渡す
    this.generateInputInformation.emit(this.generateInputForm);
  }

  /**
   * 入力フォーム状態初期化
   */
  public resetFlag() {
    // 入力エラーメッセージオブジェクトを初期化
    this.generateInputErrorMessageList = new Array();

    // 確認画面表示フラグを初期化
    this.confirmationFlag = false;

    // 登録ボタン押下フラグを初期化
    this.insertFlag = false;
  }

  /**
   * エラー項目チェック
   * @param columnId 対象項目
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(columnId: string): boolean {
    // エラーメッセージリストのエラーメッセージ存在チェック
    // 対象項目が存在する場合、trueを返却
    return this.generateInputErrorMessageList.some(
      (errorMessage) => errorMessage.columnId === columnId
    );
  }

  
  /**
   * 資材選択画面表示
   */
  public selectMaterial(){
    // 資材クリア状態を子画面に知らせる
    if (this.subSearchResultsList.length == 0) {
      this.generateInputSetMaterialComponent.initial(true);
    } else {
      this.generateInputSetMaterialComponent.initial(false);
    }
  }

  /**
   * 資材選択情報クリア
   */
  public clearMaterial() {
    this.subSearchResultsList = new Array();
    this.makeInputForm(this.subColumnOrder);
  }

  /**
   * 資材選択情報受け取り
   * @param selectSetMainList 入力フォーム情報
   */
  public receiveSelectSetMain(selectSetMainList: any) {
    this.selectedFlag = true;
    this.subSearchResultsList = selectSetMainList;
    // 入力フォーム再作成処理
    this.makeInputForm(this.subSearchResultsList);
  }

  // 入力フォームを作成
  public makeInputForm(searchResult:any){
    this.generateInputMaterialForm = this.formBuilder.group(Object());

    let count : number = 0;
    for(let count1 of searchResult){
        for (const generateInput of this.generateInputMaterialList) {
          if(generateInput.column_id == "config_count")
          // 入力フォームグループに入力フォームコントロールを追加
          this.generateInputMaterialForm.addControl(
            generateInput.column_id + count,
            this.formBuilder.control(generateInput.column_defualt, {
              asyncValidators: [
                this.validator.CheckValidator(
                  generateInput.table_id,
                  generateInput.column_id,
                  generateInput.input_type
                ),
              ],
            })
          );
        }
        count++
      }
  }

  /**
   * Validates the length of the input value against a maximum length and truncates it if necessary.
   * 
   * @param {Event} event - The event triggering the validation.
   * @param {number} maxlength - The maximum length allowed for the input value.
   */
  public validateLengthNumber(event, maxlength){
      const input = event.target as HTMLInputElement;
      if (input.value.length >= maxlength) {
        input.value = input.value.slice(0, maxlength);
      }
  }

  /**
   * Blocks non-integer input by preventing non-digit characters during a keyboard event.
   * 
   * @param {KeyboardEvent} event - The keyboard event triggering the input validation.
   */
  blockNonInteger(event: KeyboardEvent) {
    // Allow only digit characters
    if (event.key < '0' || event.key > '9') {
      event.preventDefault(); // Prevent any non-digit input
    }
  }

  /**
   * Prevents non-alphanumeric key events from being processed.
   * 
   * This method checks if the key pressed is not a letter or a number. If the key is non-alphanumeric,
   * it calls `event.preventDefault()` to block the event, ensuring that only valid input is accepted.
   * 
   * @param {KeyboardEvent} event - The keyboard event triggered by a key press.
   * @returns {void} - This method does not return a value.
   */
  blockNonAlphanumeric(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || 
        event.key === 'Backspace' || event.key === 'Delete' || 
        event.key === 'Tab') {
      return;
    }

    if (!/^[a-zA-Z0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * Removes non-alphanumeric characters from a given value and updates the form control.
   * 
   * This method takes a column identifier and a value, sanitizes the value by removing any characters
   * that are not letters or numbers, and updates the corresponding form control if the sanitized value
   * differs from the original value. It logs the column ID and the original value for debugging purposes.
   * 
   * @param {string} column_id - The identifier of the form control to be updated.
   * @param {string} value - The original value to be sanitized.
   * @returns {void} - This method does not return a value.
   */
  removeNonAlphanumeric(column_id: string, value: string) {
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
    if (sanitizedValue !== value) {
      this.generateInputForm.get(column_id).setValue(sanitizedValue, { emitEvent: false })
    }
  }

  /**
   * Handles changes to radio button selections and updates the form control accordingly.
   * 
   * This method checks if the newly selected value for a radio button is the same as the last selected value.
   * If it is, the method resets the form control to null. Otherwise, it updates the last selected value with the new selection.
   * 
   * @param {string} column_id - The identifier of the form control associated with the radio button.
   * @param {string} value - The newly selected value from the radio button.
   * @returns {void} - This method does not return a value.
   */
  onRadioChange(column_id: string, value: string){
    console.log("onRadioChange")
    console.log("column_id: ", column_id);
    console.log("value: ", value);

    if (!this.lastSelectedValues[column_id] || this.lastSelectedValues[column_id] !== value){
      this.lastSelectedValues[column_id] = value;
    }else{
      this.generateInputForm.get(column_id).setValue(null);
      this.lastSelectedValues[column_id] = null;
    }
  }
}
